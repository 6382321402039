//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


/*
 * From SearchBox.vue in Kolibri:
 * <https://github.com/learningequality/kolibri/blob/develop/kolibri/plugins/learn/assets/src/views/SearchBox.vue>
 * We need our own version because the upstream one modifies this.$router.
 */

import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'ZimSearchForm',
  components: {},
  mixins: [commonCoreStrings],
  props: {
    icon: {
      type: String,
      default: 'search',
      validator(val) {
        return ['search', 'forward'].includes(val);
      },
    },
  },
  data() {
    return {
      searchInputValue: '',
      lastSubmitValue: '',
    };
  },
  computed: {
    searchBarDisabled() {
      // Disable the search bar if it has been cleared or has not been changed
      return this.searchInputValue === this.lastSubmitValue;
    },
  },
  watch: {
    searchInputValue(value) {
      this.$emit('input', value);
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    /**
     * @public
     */
    focus() {
      this.$refs.searchInput.focus();
    },
    /**
     * @public
     */
    getInput() {
      return this.searchInputValue;
    },
    onEscKeyDown() {
      if (this.searchInputValue === '') {
        this.$emit('cancel');
      } else {
        this.clearInput();
      }
    },
    onClearButtonClick() {
      this.clearInput();
      this.$refs.searchInput.focus();
    },
    onSubmit() {
      this.lastSubmitValue = this.searchInputValue;
      if (this.searchInputValue) {
        this.$emit('submit', this.searchInputValue);
      } else {
        this.$emit('reset');
      }
    },
    clearInput() {
      this.searchInputValue = '';
      this.lastSubmitValue = '';
      this.$emit('reset');
    },
  },
  $trs: {
    clearButtonLabel: 'Clear',
    startSearchButtonLabel: 'Start search',
    searchPlaceholder: 'Search for an article',
  },
};

