//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';

export default {
  name: 'ZimBreadcrumbsMenu',
  components: {},
  props: {
    currentPathIsEnabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('zim', ['navigationHistory']),
    breadcrumbs() {
      return this.navigationHistory.slice();
    },
    visibleBreadcrumbs() {
      return this.breadcrumbs.slice(-4);
    },
  },
  methods: {
    breadcrumbIsEnabled(breadcrumb) {
      if (
        breadcrumb.path === this.$route.query.zimPath ||
        breadcrumb.path === this.$route.query.redirectFrom
      ) {
        return this.currentPathIsEnabled;
      } else {
        return true;
      }
    },
    breadcrumbTitle(breadcrumb) {
      if (breadcrumb.path === '') {
        return this.$tr('homeBreadcrumb');
      } else {
        return breadcrumb.title;
      }
    },
  },
  $trs: {
    homeBreadcrumb: 'Home',
  },
};

