//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import urls from 'kolibri.urls';
import TechnicalTextBlock from 'kolibri.coreVue.components.TechnicalTextBlock';

import debounce from 'lodash/debounce';

import ZimSearchResource from '../api-resources/zimSearchResource';
import ZimSearchForm from './ZimSearchForm';

export default {
  name: 'ZimSearchView',
  components: {
    ZimSearchForm,
    TechnicalTextBlock,
  },
  props: {
    zimFilename: {
      type: String,
    },
  },
  data() {
    return {
      isSearchMoreWaiting: 0,
      isSearchWaiting: 0,
      searchResults: {},
      suggestResults: {},
    };
  },
  computed: {
    searchHasMore() {
      return this.searchResults.next;
    },
    remainingResults() {
      if (this.searchResults.next) {
        return this.searchResults.count - this.searchResults.next;
      } else {
        return 0;
      }
    },
    startSuggestDebounced() {
      return debounce(this.startSuggest, 500);
    },
  },
  beforeDestroy() {
    this.startSuggestDebounced.cancel();
  },
  methods: {
    /**
     * @public
     */
    focus() {
      this.$refs.searchForm.focus();
    },
    onSearchFormReset() {
      this.suggestResults = {};
      this.searchResults = {};
    },
    onSearchFormInput() {
      this.startSuggestDebounced();
    },
    onSearchFormSubmit(query) {
      this.isSearchWaiting += 1;
      this.startSearch(query).finally(() => {
        this.isSearchWaiting -= 1;
      });
    },
    onSearchMoreClick() {
      this.isSearchMoreWaiting += 1;
      this.startSearch(this.searchResults.query, this.searchResults.next).finally(() => {
        this.isSearchMoreWaiting -= 1;
      });
    },
    startSuggest() {
      const query = this.$refs.searchForm.getInput();

      if (query.length < 3) {
        this.suggestResults = {};
        return;
      }

      return ZimSearchResource.search(this.zimFilename, {
        query,
        max_results: 20,
        suggest: true,
      })
        .then(result => {
          if (this.$refs.searchForm.getInput() !== query) {
            return;
          }
          const { articles } = result.data;
          this.suggestResults = {
            query,
            articles: this.filterDuplicateArticles(articles, 5),
            success: true,
          };
        })
        .catch(() => {
          this.suggestResults = {};
        });
    },
    startSearch(query, start = 0) {
      const max_results = 10;
      const snippet_length = 140;

      return ZimSearchResource.search(this.zimFilename, {
        query,
        start,
        max_results,
        snippet_length,
      })
        .then(result => {
          const { articles, count } = result.data;
          const end = start + articles.length;
          const next = end < count ? end : null;

          if (query === this.searchResults.query && start === this.searchResults.next) {
            // Add to the existing results
            this.searchResults = {
              query,
              articles: this.searchResults.articles.concat(articles),
              count,
              next,
              success: true,
            };
          } else {
            this.searchResults = {
              query,
              articles,
              count,
              next,
              success: true,
            };
          }
        })
        .catch(error => {
          this.searchResults = { query, error };
        });
    },
    filterDuplicateArticles(articles, limit) {
      // The search suggestions API tends to return many articles which
      // redirect to the same place, for example with minor spelling
      // differences. We solve this by fetching a larger number of results
      // than we will display, and removing the duplicate results.
      const articlePaths = new Set();
      return articles.reduce((result, article) => {
        const path = article.redirect || article.path;
        if (!articlePaths.has(path) && result.length < limit) {
          articlePaths.add(path);
          return result.concat(article);
        } else {
          return result;
        }
      }, []);
    },
    articleUrl(path) {
      return urls.zim_article(this.zimFilename, path);
    },
  },
  $trs: {
    searchResultsMsg:
      "{count, plural, one {{count} result} other {Top {count} results}} for '{query}'",
    noSearchResultsMsg: "No results for '{query}'",
    moreResultsMsg: '{count, plural, one {{count} more result} other {{count} more results}}',
    redirectedFromMsg: 'Redirected from { title }',
    loadMoreButtonLabel: 'Load more',
  },
};

