//
//
//
//
//
//
//


import ShuffleIconBaselineSvg from 'kolibri-design-system/lib/KIcon/precompiled-icons/material-icons/shuffle/baseline.vue';

export default {
  name: 'ShuffleIcon',
  components: {
    ShuffleIconBaselineSvg,
  },
  props: {
    color: {
      type: String,
      default: null,
    },
    disableColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedColor() {
      if (this.disableColor) {
        return null;
      }
      if (this.color) {
        return this.color;
      }
      return this.$themeTokens.text;
    },
  },
};

