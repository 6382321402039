var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zim-search"},[_c('ZimSearchForm',{ref:"searchForm",on:{"input":_vm.onSearchFormInput,"submit":_vm.onSearchFormSubmit,"reset":_vm.onSearchFormReset,"cancel":function($event){return _vm.$emit('cancel')}}}),(_vm.isSearchWaiting > 0)?_c('div',{staticClass:"zim-search-loader"},[_c('KLinearLoader')],1):_vm._e(),(_vm.suggestResults.success && _vm.suggestResults.articles.length > 0)?[_c('ol',{staticClass:"zim-suggest-results-list"},[_vm._l((_vm.suggestResults.articles),function(article,index){return [_c('li',{key:index,ref:("article" + index),refInFor:true,staticClass:"zim-suggest-result-item"},[_c('KButton',{staticClass:"zim-suggest-result-button",attrs:{"appearance":"flat-button","appearanceOverrides":{
              color: _vm.$themeTokens.link,
              ':hover': { color: _vm.$themeTokens.linkDark },
            },"text":article.title,"title":article.title,"href":_vm.articleUrl(article.path)},on:{"click":function($event){$event.preventDefault();return _vm.$emit('activate', article)}}})],1)]})],2)]:_vm._e(),(_vm.searchResults.error)?[_c('h2',[_vm._v("There was an error searching for '"+_vm._s(_vm.searchResults.query)+"'")]),_c('TechnicalTextBlock',{attrs:{"text":String(_vm.searchResults.error)}})]:(_vm.searchResults.success && _vm.searchResults.count > 0)?[_c('h2',[_vm._v("\n      "+_vm._s(_vm.$tr(
        'searchResultsMsg', {
          query: _vm.searchResults.query,
          count: _vm.$formatNumber(_vm.searchResults.articles.length)
        }
      ))+"\n    ")]),_c('ol',{staticClass:"zim-search-results-list"},[_vm._l((_vm.searchResults.articles),function(article,index){return [_c('li',{key:index,ref:("article" + index),refInFor:true,staticClass:"zim-search-result-item"},[_c('KButton',{attrs:{"appearance":"basic-link","text":article.title,"href":_vm.articleUrl(article.path)},on:{"click":function($event){$event.preventDefault();return _vm.$emit('activate', article)}}}),(article.redirect_from)?_c('small',[_vm._v("\n            "+_vm._s(_vm.$tr(
              'redirectedFromMsg', {
                title: article.redirect_from
              }
            ))+"\n          ")]):_vm._e(),(article.snippet)?_c('p',[_vm._v("\n            "+_vm._s(article.snippet)+" …\n          ")]):_vm._e()],1)]})],2),(_vm.searchHasMore)?[_c('div',{staticClass:"zim-search-footer"},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$tr(
            'moreResultsMsg', {
              count: _vm.$formatNumber(_vm.remainingResults)
            }
          ))+"\n        ")]),_c('div',{staticClass:"zim-search-more"},[_c('KButton',{attrs:{"appearance":"raised-button","primary":"true","disabled":_vm.isSearchWaiting > 0 || _vm.isSearchMoreWaiting > 0,"text":_vm.$tr('loadMoreButtonLabel')},on:{"click":function($event){$event.preventDefault();return _vm.onSearchMoreClick.apply(null, arguments)}}}),(_vm.isSearchMoreWaiting > 0)?_c('KCircularLoader'):_vm._e()],1)])]:_vm._e()]:(_vm.searchResults.success && _vm.searchResults.count === 0)?[_c('h2',[_vm._v(_vm._s(_vm.$tr('noSearchResultsMsg', { query: _vm.searchResults.query })))])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }