//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import urls from 'kolibri.urls';
import { mapActions } from 'vuex';
import CoreFullscreen from 'kolibri.coreVue.components.CoreFullscreen';

import debounce from 'lodash/debounce';

import ZimBreadcrumbsMenu from './ZimBreadcrumbsMenu';
import ZimContentView from './ZimContentView';
import ZimSearchView from './ZimSearchView';
import ShuffleIcon from './ShuffleIcon';

const defaultFullscreenHeaderHeight = '37px';

export default {
  name: 'ZimRendererIndex',
  components: {
    CoreFullscreen,
    ZimSearchView,
    ZimContentView,
    ZimBreadcrumbsMenu,
    ShuffleIcon,
  },
  data() {
    return {
      isInFullscreen: false,
      isSearching: false,
      isLoading: false,
      fullscreenHeaderHeight: defaultFullscreenHeaderHeight,
      resizeObserver: null,
    };
  },
  computed: {
    zimFilename() {
      const defaultFile = this.defaultFile;
      return `${defaultFile.checksum}.${defaultFile.extension}`;
    },
    iframeWidth() {
      return (this.options && this.options.width) || 'auto';
    },
    fullscreenText() {
      return this.isInFullscreen ? this.$tr('exitFullscreen') : this.$tr('enterFullscreen');
    },
    searchText() {
      return this.$tr('search');
    },
    randomArticleText() {
      return this.$tr('randomArticle');
    },
    navSearchButtonStyle() {
      if (this.isSearching) {
        return { backgroundColor: this.$themePalette.grey.v_300 };
      } else {
        return { backgroundColor: this.$themePalette.grey.v_200 };
      }
    },
    mainContainerStyle() {
      if (this.isInFullscreen) {
        return {
          position: 'absolute',
          top: this.fullscreenHeaderHeight,
          bottom: 0,
        };
      } else {
        return {};
      }
    },
    onNavRandomArticleClickDebounced() {
      return debounce(this.onNavRandomArticleClick, 500, { leading: true });
    },

    /* eslint-disable kolibri/vue-no-unused-properties */
    // Note: the default duration historically for HTML5 Apps has been 5 min,
    // so we can use the same for zim files
    // Based on:
    // https://github.com/learningequality/kolibri/blob/release-v0.15.x/kolibri/plugins/html5_viewer/assets/src/views/Html5AppRendererIndex.vue#L124-L126
    defaultDuration() {
      return 300;
    },
  },
  mounted() {
    this.initResizeObserver();
    this.$emit('startTracking');
    this.pollProgress();
  },
  beforeDestroy() {
    this.destroyResizeObserver();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.$emit('stopTracking');
  },
  methods: {
    ...mapActions('zim', ['pushNavigationHistory', 'resetNavigationHistory']),
    initResizeObserver() {
      // It would be nice to polyfill ResizeObserver, but the default case
      // should work reasonably well in most situations.
      this.resizeObserver = new ResizeObserver(this.onFullscreenHeaderResize);
      this.resizeObserver.observe(this.$refs.fullscreenHeader);
      this.onFullscreenHeaderResize();
    },
    destroyResizeObserver() {
      if (this.resizeObserver) this.resizeObserver.disconnect();
    },
    onFullscreenHeaderResize() {
      this.fullscreenHeaderHeight = this.$refs.fullscreenHeader.offsetHeight + 'px';
    },
    recordProgress() {
      this.$emit('updateProgress', this.durationBasedProgress);
      this.pollProgress();
    },
    pollProgress() {
      this.timeout = setTimeout(() => {
        this.recordProgress();
      }, 15000);
    },
    onNavSearchClick() {
      this.isSearching = true;
      this.$nextTick(() => {
        this.$refs.zimSearchView.focus();
      });
    },
    onNavRandomArticleClick() {
      this.isSearching = false;
      return fetch(urls.zim_random_article(this.zimFilename))
        .then(response => {
          return response.json();
        })
        .then(json => {
          const zimPath = json.zimPath;
          if (zimPath) {
            this.$router.push({ query: { zimPath } });
          }
        });
    },
    onNavBreadcrumbActivate(breadcrumb) {
      this.isSearching = false;
      this.$router.push({ query: { zimPath: breadcrumb.path } });
    },
    onZimSearchViewActivate({ path }) {
      this.isSearching = false;
      this.resetNavigationHistory();
      this.$router.push({ query: { zimPath: path } });
    },
    onZimSearchViewCancel() {
      this.isSearching = false;
    },
    onZimSearchOverlayClick(event) {
      if (event.target === this.$refs.zimSearchOverlay) {
        this.isSearching = false;
      }
    },
    onZimContentViewArticleLoading() {
      this.isLoading = true;
    },
    onZimContentViewArticleReady({ zimPath, redirectFrom, title }) {
      this.isLoading = false;
      this.pushNavigationHistory({ path: zimPath, redirectFrom, title });
    },
  },
  $trs: {
    enterFullscreen: 'View Fullscreen',
    exitFullscreen: 'Exit Fullscreen',
    randomArticle: 'Random Article',
    search: 'Search',
  },
};

